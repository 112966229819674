<!-- 
	This is the sign in page, it uses the dashboard layout in: 
	"./layouts/Default.vue" .
 -->

<template>
  <div class="sign-in">
    <a-row type="flex" :gutter="[24,24]" justify="space-around" align="middle">
      <!-- Sign In Form Column -->
      <a-col :span="24" :md="12" :lg="{span: 12, offset: 0}" :xl="{span: 6, offset: 2}" class="col-form">
        <h1 class="mb-15">登录</h1>
        <h5 class="font-regular text-muted">请输入手机号和密码进行登录</h5>

        <!-- Sign In Form -->
        <a-form id="components-form-demo-normal-login" :form="form" class="login-form" @submit="handleSubmit" :hideRequiredMark="true">
          <a-form-item class="mb-10" label="Phone" :colon="false">
            <a-input
              v-decorator="[
						'phone',
						{ rules: [{ required: true, message: 'Please input your Phone!' }] },
						]"
              placeholder="手机号"
            />
          </a-form-item>
          <a-form-item class="mb-5" label="Password" :colon="false">
            <a-input
              v-decorator="[
						'password',
						{ rules: [{ required: true, message: 'Please input your password!' }] },
						]"
              type="password"
              placeholder="密码"
            />
          </a-form-item>
          <a-form-item class="mb-10">
            <a-switch v-model="rememberMe" />记住我
          </a-form-item>
          <a-form-item>
            <a-button type="primary" block html-type="submit" :loading="loading" class="login-form-button">登录</a-button>
          </a-form-item>
        </a-form>
        <!-- / Sign In Form -->

        <!-- <p class="font-semibold text-muted">
          Don't have an account?
          <router-link to="/sign-in" class="font-bold text-dark">Sign Up</router-link>
        </p>-->
      </a-col>
      <!-- / Sign In Form Column -->

      <!-- Sign In Image Column -->
      <a-col :span="24" :md="12" :lg="12" :xl="12" class="col-img">
        <img src="images/img-signin.jpg" alt />
      </a-col>
      <!-- Sign In Image Column -->
    </a-row>
  </div>
</template>

<script>
import router from '@/router/index'
import { loginAccount } from '@/api/index'
import { setToken } from '@/utils/auth'
// console.log('router---', router)
export default {
  data() {
    return {
      loading: false,
      // Binded model property for "Sign In Form" switch button for "Remember Me" .
      rememberMe: true
    }
  },
  beforeCreate() {
    // Creates the form and adds to it component's "form" property.
    this.form = this.$form.createForm(this, { name: 'normal_login' })
  },
  methods: {
    // Handles input validation after submission.
    handleSubmit(e) {
      e.preventDefault()
      this.form.validateFields((err, values) => {
        if (!err) {
          //   this.$message.success(`ssss file uploaded successfully`)
          //   this.$confirm({
          //     title: '系统提示',
          //     content: '登录状态已过期，您可以继续留在该页面，或者重新登录',
          //     cancelText: '取消',
          //     okText: '确定'
          //   })
          this.loading = true
          loginAccount(values)
            .then(res => {
              console.log('loginAccount---', res)
              setToken(res.data.access_token)
              this.loading = false
              setTimeout(() => {
                router.push('/tables')
              }, 1e3)
            })
            .catch(() => {
              this.loading = false
            })
          //   this.info()
          //   debugger
          //Abc1234%

          // console.log('Received values of form: ', values)
        }
      })
    },
    info() {
      const h = this.$createElement
      this.$info({
        title: 'This is a notification message',
        content: h('div', {}, [h('p', 'some messages...some messages...'), h('p', 'some messages...some messages...')]),
        onOk() {}
      })
    }
  }
}
</script>

<style lang="scss">
body {
  background-color: #ffffff;
}
</style>